import { AxiosResponse } from "axios"
import { pickBy } from "lodash"
import { Dispatch } from "redux"

import API from "../../Api"
import AuthHelper from "../../helpers/AuthHelper"
import { ReportRequestModuleEnum } from "../../api/reportrequest/reportrequest.dto"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { toast } from "react-toastify"

dayjs.extend(utc)
dayjs.extend(timezone)

export interface CallLogsParams {
  caller?: string
  callee?: string
  dateFrom?: string
  dateTo?: string
  duration?: number
  page?: number
  organizationId?: string
  organizationName?: string
  programId?: string
  programName?: string
  type?: string
  status?: string
  isAnonymous?: string
  callListQueueId?: string
}

type FetchCallLogs = (params: CallLogsParams) => (dispatch: Dispatch) => void
type ExportCallLogs = (params: CallLogsParams) => (dispatch: Dispatch) => void
type ClearExportedCallLogs = () => void

export interface CallLogsActions {
  clearExportedCallLogs: ClearExportedCallLogs
  clearAnonymousExportedCallLogs: ClearExportedCallLogs
  exportCallLogs: ExportCallLogs
  exportAnonymousCallLogs: ExportCallLogs
  fetchCallLogs: FetchCallLogs
  createReportCallLogs: ExportCallLogs
}

class CallLogs {
  public static FETCH_CALL_LOGS_SUCCESS = "FETCH_CALL_LOGS_SUCCESS"
  public static FETCH_CALL_LOGS_TRIGGER = "FETCH_CALL_LOGS_TRIGGER"
  public static FETCH_CALL_LOGS_ERROR = "FETCH_CALL_LOGS_ERROR"

  public static EXPORT_CALL_LOGS_TRIGGER = "EXPORT_CALL_LOGS_TRIGGER"
  public static EXPORT_CALL_LOGS_SUCCESS = "EXPORT_CALL_LOGS_SUCCESS"
  public static EXPORT_CALL_LOGS_ERROR = "EXPORT_CALL_LOGS_ERROR"

  public static CREATE_REPORT_REQUEST_CALL_LOGS_TRIGGER = "CREATE_REPORT_REQUEST_CALL_LOGS_TRIGGER"
  public static CREATE_REPORT_REQUEST_CALL_LOGS_SUCCESS = "CREATE_REPORT_REQUEST_CALL_LOGS_SUCCESS"
  public static CREATE_REPORT_REQUEST_CALL_LOGS_ERROR = "CREATE_REPORT_REQUEST_CALL_LOGS_ERROR"

  public static ANONYMOUS_EXPORT_CALL_LOGS_TRIGGER = "ANONYMOUS_EXPORT_CALL_LOGS_TRIGGER"
  public static ANONYMOUS_EXPORT_CALL_LOGS_SUCCESS = "ANONYMOUS_EXPORT_CALL_LOGS_SUCCESS"
  public static ANONYMOUS_EXPORT_CALL_LOGS_ERROR = "ANONYMOUS_EXPORT_CALL_LOGS_ERROR"

  public static EXPORT_CALL_LOGS_CLEAR = "EXPORT_CALL_LOGS_CLEAR"
  public static ANONYMOUS_EXPORT_CALL_LOGS_CLEAR = "ANONYMOUS_EXPORT_CALL_LOGS_CLEAR"

  public static fetchCallLogs: FetchCallLogs =
    ({ callListQueueId, duration, organizationId, page, caller, callee, status, dateFrom, dateTo, programId, type }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: CallLogs.FETCH_CALL_LOGS_TRIGGER,
        })
        const params = pickBy(
          {
            callee: callee || null,
            caller: caller || null,
            dateFrom: dateFrom || null,
            dateTo: dateTo || null,
            duration: duration || null,
            organizationId: organizationId || null,
            programId: programId || null,
            page: page || 0,
            status: status || null,
            type: type || null,
            callListQueueId: callListQueueId || null,
          },
          (value) => value !== null,
        )
        const res = await API.get("/v2/calls", {
          params,
          headers: AuthHelper.getAdminHeaders(),
        })

        if (!res.data.data) {
          throw new Error("Invalid server response")
        }
        dispatch({
          payload: res.data.data,
          type: CallLogs.FETCH_CALL_LOGS_SUCCESS,
        })
      } catch (err) {
        dispatch({
          payload: "Oops!... Looks like an error occurred!",
          type: CallLogs.FETCH_CALL_LOGS_ERROR,
        })
      }
    }

  public static exportCallLogs: ExportCallLogs =
    ({ duration, organizationId, programId, type, page, caller, callee, status, dateFrom, dateTo }) =>
    async (dispatch) => {
      let res: AxiosResponse

      dispatch({
        type: CallLogs.EXPORT_CALL_LOGS_TRIGGER,
      })

      try {
        const params = pickBy(
          {
            callee: callee || null,
            caller: caller || null,
            dateFrom: dateFrom || null,
            dateTo: dateTo || null,
            duration: duration || null,
            organizationId: organizationId || null,
            programId: programId || null,
            page: page || 0,
            status: status || null,
            type: type || null,
          },
          (value) => value !== null,
        )
        res = await API.get("/v2/calls/csv", {
          params,
          headers: AuthHelper.getAdminHeaders(),
          responseType: "blob",
        })

        const { data } = res

        dispatch({
          type: CallLogs.EXPORT_CALL_LOGS_SUCCESS,
          payload: { data },
        })

        return
      } catch (err) {
        dispatch({
          type: CallLogs.EXPORT_CALL_LOGS_ERROR,
        })

        return
      }
    }

  public static exportAnonymousCallLogs: ExportCallLogs =
    ({ duration, organizationId, programId, page, caller, callee, status, dateFrom, dateTo, isAnonymous }) =>
    async (dispatch) => {
      let res: AxiosResponse

      dispatch({
        type: CallLogs.ANONYMOUS_EXPORT_CALL_LOGS_TRIGGER,
      })

      try {
        res = await API.get("/v2/calls/csv", {
          params: {
            callee: callee || null,
            caller: caller || null,
            dateFrom: dateFrom || null,
            dateTo: dateTo || null,
            duration: duration || null,
            organizationId: organizationId || null,
            programId: programId || null,
            page: page || 0,
            status: status || null,
            isAnonymous: isAnonymous || null,
          },
          headers: AuthHelper.getAdminHeaders(),
          responseType: "blob",
        })

        const { data } = res

        dispatch({
          type: CallLogs.ANONYMOUS_EXPORT_CALL_LOGS_SUCCESS,
          payload: { data },
        })

        return
      } catch (err) {
        dispatch({
          type: CallLogs.ANONYMOUS_EXPORT_CALL_LOGS_ERROR,
        })

        return
      }
    }

  public static createReportCallLogs: ExportCallLogs =
    ({ duration, organizationId, programId, organizationName, programName, type, caller, callee, status, dateFrom, dateTo }) =>
    async (dispatch) => {
      dispatch({
        type: CallLogs.CREATE_REPORT_REQUEST_CALL_LOGS_TRIGGER,
      })

      try {
        const now = new Date().getTime()
        const { userId } = AuthHelper.getUserAuth()
        const payload = pickBy(
          {
            organizationId: organizationId || null,
            organizationName: organizationName || null,
            requestedById: userId,
            module: ReportRequestModuleEnum.CALLLOGS,
            filters: {
              startDate: dateFrom ? new Date(dateFrom).getTime() : now,
              endDate: dateTo ? new Date(dateTo).getTime() : now,
              tz: dayjs.tz.guess(),
              programId: programId || null,
              programName: programName || null,
              params: {
                ...(type ? { type: type } : {}),
                ...(duration ? { duration: duration } : {}),
                ...(caller ? { caller: caller } : {}),
                ...(callee ? { callee: callee } : {}),
                ...(status ? { status: status } : {}),
                ...(programId ? { programId } : {}),
              },
            },
          },
          (value) => value !== null,
        )
        await API.post("/v2/reportrequest/create", payload, {
          headers: AuthHelper.getAdminHeaders(),
        })

        dispatch({
          type: CallLogs.CREATE_REPORT_REQUEST_CALL_LOGS_SUCCESS,
        })
        toast("Report request created successfully, you will be notified when it's ready for download", { type: "success" })
        return
      } catch (err) {
        toast("Error creating report request, try again later", { type: "error" })
        dispatch({
          type: CallLogs.CREATE_REPORT_REQUEST_CALL_LOGS_ERROR,
        })

        return
      }
    }

  public static clearAnonymousExportedCallLogs = () => (dispatch: Dispatch) => {
    dispatch({
      type: CallLogs.ANONYMOUS_EXPORT_CALL_LOGS_CLEAR,
    })
  }

  public static clearExportedCallLogs = () => (dispatch: Dispatch) => {
    dispatch({
      type: CallLogs.EXPORT_CALL_LOGS_CLEAR,
    })
  }
}

export default CallLogs
